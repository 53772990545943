<template>
  <v-container>
    <v-row class="mx-2">
      <v-btn @click.native="WhsDialog = true" class="primary">Add</v-btn>
      <v-col v-if="record" cols="12" sm="12" md="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="WhsAssgnHeaders"
          :items="WhsAssgnments"
          :search="searchWhs"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
          :server-search="true"
        >
          <template v-slot:item.created_at="{ item }">{{
            item.created_at | moment("LLL")
          }}</template>
          <template v-slot:item.Active="{ item }">
            {{ item.Active ? "Active" : "In-Active" }}
          </template>
          <template v-slot:item.StockActions="{ item }">
            {{ item.StockActions == 1 ? "Issue" : "Receipt" }}
          </template>
          <template v-slot:item.id="props">
            <v-btn
              @click="removeItem(props.item.id, '/assign_warehouse')"
              color="white"
              plain
              small
            >
              <v-icon color="red">mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <!-- one item selection modal -->
      <v-dialog v-model="WhsDialog" width="950">
        <v-toolbar dark color="primary">
          <v-toolbar-title>WareHouse Assignment</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="WhsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-autocomplete
                    :items="Warehouses"
                    item-value="WhsCode"
                    :item-text="(item) => item.WhsCode + '  -  ' + item.WhsName"
                    outlined
                    v-model="WhsAssgn.WhsCode"
                    label="Warehouse"
                    @change="onWarehouseSelected"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4">
                  <v-autocomplete
                    :items="Actions"
                    outlined
                    dense
                    label="Stock Actions"
                    v-model="WhsAssgn.StockActions"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    outlined
                    type="number"
                    dense
                    label="SalesLimit"
                    v-model="WhsAssgn.SalesLimit"
                  ></v-text-field>
                </v-col>

                <v-col v-if="this.SelectedWhs.BinActivat == '1'" cols="4">
                  <v-autocomplete
                    multiple
                    :items="BinLocations"
                    search-input="true"
                    item-value="BinCode"
                    :item-text="(item) => item.BinCode + '  -  ' + item.Descr"
                    outlined
                    search="search"
                    v-model="WhsAssgn.BinCodes"
                    label="Bin Locations"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-btn color="accent" @click="AssignWhs" :loading="loader">
                    <v-icon left>mdi-content-save</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end of item modal -->
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    record: {},
    WhsAssgnments: [],
    loader: false,
    Warehouses: [],
    Payment: {},
    WhsAssgn: {},
    search: "",
    searchWhs: null,
    WhsDialog: false,
    SelectedWhs: {},
    BinLocations: [],
    Actions: [
      { value: 1, text: "Issue" },
      { value: 2, text: "Receipt" },
      // { value: 3, text: "Both" },
    ],
    WhsAssgnHeaders: [
      { text: "Whs Code", value: "WhsCode" },
      { text: "Whs Name", value: "WhsName" },
      { text: "Stock Actions", value: "StockActions" },
      { text: "Active", value: "Active" },
      { text: "Created On", value: "created_at" },
      { text: "Actions", value: "id" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 10,
      page: 1,
    },
    totalItems: 0,
  }),

  watch: {
    options: {
      handler() {
        this.warehouseCollection();
      },
      deep: true,
    },
  },
  methods: {
    onWarehouseSelected(value) {
      this.SelectedWhs = this.Warehouses.find((Warehouse) => Warehouse.WhsCode === value);
      this.BinLocations = this.SelectedWhs.binlocations;
      if (this.SelectedWhs.BinActivat == "1") {
        this.WhsAssgn.BinActivat = "Y";
      }
      console.log(this.BinLocations);
    },
    getWarehouses() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.Warehouses = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          self.loading = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },

    warehouseCollection() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch(
          "get",
          `/employee/${this.$route.params.empID}/warehouseCollection?page=${this.options.page}&per_page=${this.options.itemsPerPage}`
        )
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.WhsAssgnments = res.ResponseData.data;
            self.options.page = res.ResponseData.current_page;
            self.options.itemsPerPage = res.ResponseData.per_page;
            self.totalItems = res.ResponseData.total;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          self.loading = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },

    AssignWhs() {
      let data = this.WhsAssgn;
      const url = `/assign_warehouse/${this.$route.params.empID}`;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.warehouseCollection();
          } else {
            self.loading = false;
            this.$refs.snackbar.show(res.ResultDesc, "red");
          }
          this.WhsDialog = false;
        })
        .catch((err) => {
          self.$store.commit("loader", false);
          self.$refs.snackbar.show(err.response?.data?.ResultDesc, "red");
        });
    },
    getBpartners() {
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          let Partners = {
            CardCode: "-1",
            CardName: "ALL",
          };
          this.BusinessPartners = res.ResponseData;
          this.BusinessPartners.push(Partners);
          this.BusinessPartners.sort((a, b) => a.CardCode - b.CardCode);
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    removeItem(id, baseurl) {
      var userResponse = confirm(
        "Are you sure you want to delete? This action cannot be undone."
      );
      if (userResponse === true) {
        const url = `${baseurl}/${id}`;
        const self = this;
        self.$store
          .dispatch("remove", url)
          .then((res) => {
            if (res.ResultCode == 1200) {
              location.reload();
            } else {
              self.loading = false;
              this.$refs.snackbar.show(res.ResultDesc, "red");
            }
            this.Dialog = false;
            this.WhsDialog = false;
          })
          .catch((err) => {
            self.$store.commit("loader", false);
            self.$refs.snackbar.show(err.response?.data?.ResultDesc, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },
  },
  created() {
    this.getWarehouses();
    this.warehouseCollection();
  },
};
</script>
